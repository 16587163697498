<template>
  <v-row class="justify-space-around mx-1">
    <v-sheet
      v-if="q !== undefined"
      class="col-12"
    >
      <v-card class="mt-0">
        <v-list-item class="mr-1">
          <v-list-item-content>
            <v-list-item-subtitle class="font-weight-light">
              {{ q.exam }} 問{{ q.question_num }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <!-- カテゴリの表示-->
        <v-row class="ma-auto px-4">
          <v-chip
            small="small"
            outlined="outlined"
          >
            {{ q.subject_category }}
          </v-chip><span class="font-weight-thin">/</span>
          <v-chip
            small="small"
            outlined="outlined"
          >
            {{ q.category }}
          </v-chip>
          <template v-if="q.sub_category &amp;&amp; q.sub_category !== 'TBD' ">
            <span class="font-weight-thin">/</span>
            <v-chip
              small="small"
              outlined="outlined"
            >
              {{ q.sub_category }}
            </v-chip>
          </template>
        </v-row>
        <!--問題文-->

        <!-- eslint-disable vue/no-v-text-v-html-on-component vue/no-v-html -->
        <v-card-text
          class="font-weight-black statement pb-0 mb-0"
          v-html="q.statement"
        />
        <!-- eslint-enable vue/no-v-text-v-html-on-component vue/no-v-html -->

        <!--回答ボタン-->
        <v-row class="mx-1 mr-3">
          <v-btn
            class="ma-3"
            color="primary"
            dark="dark"
            @click="isAnswerCorrect(1)"
          >
            <v-icon>mdi-circle-outline</v-icon>
          </v-btn>
          <v-btn
            class="ma-3"
            color="primary"
            dark="dark"
            @click="isAnswerCorrect(0)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer />
          <v-btn
            class="ma-3"
            outlined="outlined"
            color="primary"
            @click="show_ans=!show_ans"
          >
            {{ show_ans ? "答えを隠す" : "分からないので答えを見る" }}
          </v-btn>
          <!-- 正解・失敗のdialog-->
          <v-fade-transition>
            <v-overlay
              v-if="overlay &amp;&amp; q.correct"
              color="success"
              absolute="absolute"
              light="light"
              opacity="0.1"
            >
              <v-card
                v-show="overlay"
                color="success"
                width="200"
                @click="overlay = false"
              >
                <v-row justify="center">
                  <v-icon
                    color="white"
                    large="large"
                  >
                    mdi-check
                  </v-icon>
                  <v-card-title class="text--black">
                    正解
                  </v-card-title>
                </v-row>
              </v-card>
            </v-overlay>
            <v-overlay
              v-if="overlay &amp;&amp; !q.correct"
              color="red"
              absolute="absolute"
              light="light"
              opacity="0.1"
            >
              <v-card
                v-show="overlay"
                color="red"
                width="200"
                @click="overlay = false"
              >
                <v-row justify="center">
                  <v-icon
                    color="white"
                    large="large"
                  >
                    mdi-close
                  </v-icon>
                  <v-card-title class="text--black">
                    残念
                  </v-card-title>
                </v-row>
              </v-card>
            </v-overlay>
          </v-fade-transition>
        </v-row>
      </v-card>
      <!--解説-->
      <v-expand-transition>
        <question-description
          v-show="show_ans"
          :ans="q.answer"
          :desc="q.description"
          @desc-modal="showModal"
        />
      </v-expand-transition>
      <!-- 法律モーダル-->
      <v-fade-transition>
        <v-dialog
          v-if="modalToShow"
          v-model="overlayModal"
          width="700px"
        >
          <law-modal :law-text="modalToShow" />
        </v-dialog>
      </v-fade-transition>
    </v-sheet>
  </v-row>
</template>

<script>
import QuestionDescription from "@/components/Question/QuestionDescription";
import LawModal from "@/components/Question/LawModal";

export default {
  name: "Question",
  components: { LawModal, QuestionDescription },
  data() {
    return {
      show_ans: false,
      overlay: false,
      modalToShow: null,
      overlayModal: false,
      q:
        {
          "id": 717,
          "exam": "令和2年第2回",
          "question_num": "1.ア",
          "statement": "<p>個人商人が，その氏又は氏名以外の名称を商号として選定した場合には，当該商号を登記しなければならない。</p>\n",
          "reference": null,
          "answer": 0,
          "description": `<a href='#' v-on:click.prevent="$parent.$emit('desc-modal', '商法-11')">商法11条</a>2項。個人商人は，その商号の登記をすることができる。なお，小商人については，一般の商人の商号選択の自由を狭めるおそれがあることを考慮して，<a href='#' v-on:click.prevent="$parent.$emit('desc-modal', '商法-11')">商法11条</a>2項は適用されないことから（<a href='#' v-on:click.prevent="$parent.$emit('desc-modal', '商法-7')">商法7条</a>），小商人は商号を登記することができない。\n`,
          "category_id": 80,
          "law_id": null,
          "subject_category": "企業法",
          "category": "商法",
          "sub_category": "商法総則",
          "flg": null,
          "last_answer": null,
          "page": 1,
          "correct": "",
          "modals": {
            "商法-11": "<h1>第11条（商号の選定）</h1><ol><li>商人（会社及び外国会社を除く。以下この編において同じ。）は、その氏、氏名その他の名称をもってその商号とすることができる。</li><ol></ol><li>商人は、その商号の登記をすることができる。</li><ol></ol></ol>",
            "商法-7": "<h1>第7条（小商人）</h1><ol><li>第五条、前条、次章、第十一条第二項、第十五条第二項、第十七条第二項前段、第五章及び第二十二条の規定は、小商人（商人のうち、法務省令で定めるその営業のために使用する財産の価額が法務省令で定める金額を超えないものをいう。）については、適用しない。</li><ol></ol></ol>"
          }
        }

    }
  },
  watch: {
    overlay(val) {
      if (!val) return;

      setTimeout(() => {
        this.overlay = false;
        this.show_ans = true;
      }, 1000)
    },
  },
  mounted() {
    this.reRender();
  },
  methods: {
    reRender() {
      if (window.MathJax) {
        window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub]);
      }
    },
    isAnswerCorrect(n) {
      this.q.correct = this.q.answer === n;
      return this.overlay = true;
    },
    showModal(e) {
      this.overlayModal = true;
      this.modalToShow = this.q.modals[e];
    },
  },
}
</script>

<style scoped>
.responsive-img >>> img {
  max-width: 500px;
  min-width: 100px;
  width: 100%;
}

.statement {
  white-space: pre-line
}

.statement >>> p {
  margin-bottom: 0 !important;
}
</style>

