<template>
  <v-card class="mx-auto my-3">
    <v-card-title
      v-show="showAnswer"
      class="py-3 justify-center"
    >
      <v-icon
        v-if="ans"
        color="primary"
        large="large"
      >
        mdi-circle-outline
      </v-icon>
      <v-icon
        v-else
        color="primary"
        large="large"
      >
        mdi-close
      </v-icon>
    </v-card-title>
    <v-divider />
    <v-card-text v-show="showDesc">
      <v-row class="justify-end">
        <v-tooltip top="top">
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-show="firstCorrectRateText !==''"
              class="text-caption mb-3"
              outlined="outlined"
              v-bind="attrs"
              v-on="on"
            >
              {{ firstCorrectRateText }}
            </v-chip>
          </template><span>全利用者の初回答をもとに算出</span>
        </v-tooltip>
      </v-row>
      <v-runtime-template
        ref="tmp"
        :template="'&lt;div&gt;'+desc+'&lt;/div&gt;'"
        v-on="$listeners"
      />
      <div
        v-if="showImage"
        class="mt-3"
      >
        <v-img
          :src="imageUrl"
          contain="contain"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import VRuntimeTemplate from 'v-runtime-template';

export default {
  name: "QuestionDescription",
  components: { VRuntimeTemplate },
  props: {
    singleMode: { type: Boolean, default: true },
    desc: { type: String, default: '' },
    ans: { type: Number, default: 0 },
    imageUrl: { type: String, default: '' },
    firstCorrectRate: { type: Number, default: 0 },
    showAnswer: { type: Boolean, default: false },
  },
  data() {
    return {
      showDesc: true
    }
  },
  computed: {
    answerText() {
      if (!this.showAnswer) return ' ';
      return this.ans ? '正しい' : '誤り';
    },
    showImage() {
      return this.imageUrl && this.imageUrl.startsWith('http')
    },
    firstCorrectRateText() {
      if (!this.showAnswer) return '';
      if (this.firstCorrectRate === undefined || this.firstCorrectRate === null) return '';

      const percentage = (this.firstCorrectRate * 100).toFixed(0)
      return `全体正答率${percentage}%`
    }
  },
  watch: {
    showAnswer(show) {
      if (!show) {
        this.showDesc = false;
        setTimeout(() => this.showDesc = true, 1000);
      }
    }
  }
}
</script>

<style scoped>

</style>
